import uuid from "uuid/v4";

export const SessionManager = {
  setSessionId(id = uuid()) {
    window.sessionStorage.setItem("replicated.vendor.sessionid", id);
  },

  getSessionId() {
    var sessionId = window.sessionStorage.getItem("replicated.vendor.sessionid");
    if (!sessionId) {
      sessionId = uuid();
      this.setSessionId(sessionId);
    }
    return sessionId;
  },

  getBuildVersion() {
    return import.meta.env.VITE_VENDOR_WEB_BUILD_VERSION;
  },

  getPushEndpoint() {
    return `${import.meta.env.VITE_PUSH_ENDPOINT}`;
  },

  getUnifiedApiEndpoint() {
    return `${import.meta.env.VITE_API_ENDPOINT}/v1`;
  },

  getApiEndpoint(version = "1") {
    switch (version) {
      case "3":
        return `${import.meta.env.VITE_VENDOR_API_ENDPOINT}/v3`;
      case "2":
        return `${import.meta.env.VITE_VENDOR_API_ENDPOINT}/v2`;
      case "1":
        return `${import.meta.env.VITE_VENDOR_API_ENDPOINT}/v1`;
      default:
        return `${import.meta.env.VITE_VENDOR_API_ENDPOINT}/v1`;
    }
  }
};
