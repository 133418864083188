/* eslint-disable import/no-unused-modules */
export const NODE_ENV = import.meta.env.NODE_ENV;
export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;
export const PUSH_ENDPOINT = import.meta.env.VITE_PUSH_ENDPOINT;
export const UNIFIED_API_ENDPOINT = import.meta.env.VITE_UNIFIED_API_ENDPOINT;
export const VENDOR_API_ENDPOINT = import.meta.env.VITE_VENDOR_API_ENDPOINT;
export const REPLICATED_APP_ENDPOINT = import.meta.env.VITE_REPLICATED_APP_ENDPOINT;
export const KOTS_LINT_ENDPOINT = import.meta.env.VITE_KOTS_LINT_ENDPOINT;
export const REGISTRY_HOSTNAME = import.meta.env.VITE_REGISTRY_HOSTNAME;
export const VENDOR_WEB_BUILD_VERSION = import.meta.env.VITE_VENDOR_WEB_BUILD_VERSION;
export const BUGSNAG_RELEASE_STAGE = import.meta.env.VITE_BUGSNAG_RELEASE_STAGE;
export const BUGSNAG_API_KEY = import.meta.env.VITE_BUGSNAG_API_KEY;
export const RETRACED_VIEWER_ENDPOINT = import.meta.env.VITE_RETRACED_VIEWER_ENDPOINT;
export const RETRACED = import.meta.env.VITE_RETRACED;
export const INSTALL_SCRIPTS_ENDPOINT = import.meta.env.VITE_INSTALL_SCRIPTS_ENDPOINT;
export const KURL_URL = import.meta.env.VITE_KURL_URL;
export const PUSHER_KEY = import.meta.env.VITE_PUSHER_KEY;
export const PUSHER_CLUSTER = import.meta.env.VITE_PUSHER_CLUSTER;
export const PUBLIC_ASSET_PATH = import.meta.env.VITE_PUBLIC_ASSET_PATH;
export const DATADOG_APPLICATION_ID = import.meta.env.VITE_DATADOG_APPLICATION_ID;
export const DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
export const GITHUB_CLIENT_ID = import.meta.env.VITE_GITHUB_CLIENT_ID;
export const GITHUB_INSTALL_URL = import.meta.env.VITE_GITHUB_INSTALL_URL;

export const endpoints = {
  api: `${API_ENDPOINT}/v1`,
  vendor: `${VENDOR_API_ENDPOINT}/v3`,
  vendorV1: `${VENDOR_API_ENDPOINT}/v1`,
  replicatedApp: REPLICATED_APP_ENDPOINT
};
