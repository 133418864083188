import cx from "classnames";
import Markdown from "./Markdown";

const TooltipContent = ({
  className,
  markdown
}: {
  className?: string;
  markdown: string;
}) => (
  <div
    data-testid="tooltip-content"
    className={cx(
      "tw-font-normal tw-text-center tw-text-xs tw-leading-5 tw-text-neutral-700",
      className
    )}
  >
    <Markdown text={markdown} />
  </div>
);

export default TooltipContent;
