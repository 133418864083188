// @ts-strict
import { sanitizeUrl } from "@braintree/sanitize-url";
import { isEmpty } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";

import AccountLocked from "@/components/containers/AccountLocked";
import AccountUnlock from "@/components/containers/AccountUnlock";
import Logout from "@/components/containers/Logout";
import PasswordReset from "@/components/containers/PasswordReset";
import ReplRedirect from "@/components/containers/ReplRedirect";
import RequireTwoFactor from "@/components/containers/RequireTwoFactor";
import LogOutErrorModal from "@/components/modals/LogOutErrorModal";
import CompromisedPasswordBanner from "@/components/shared/CompromisedPasswordBanner";
import LegacySchedulersEOLBanner from "@/components/shared/LegacySchedulersEOLBanner";
import { PageTitle } from "@/components/shared/PageTitle";
import MultipleTeamsError from "@/components/static/MultipleTeamsError";
import NotFound from "@/components/static/NotFound";
import PrivacyPolicy from "@/components/static/PrivacyPolicy";
import TermsOfService from "@/components/static/TermsOfService";
import { VendorUtilities } from "@/utilities/VendorUtilities";

import NavBar from "./NavBar";
import RedirectChangelog from "./RedirectChangelog";

import {
  AsyncAccountSettings,
  AsyncApps,
  AsyncAppsTroubleshootContainer,
  AsyncCLILogin,
  AsyncCreateApp,
  AsyncSupport,
  AsyncTeamSettingsComponent,
  AsyncTroubleshootContainer
} from "./AsyncMainComponents";

import AuthGoogleError from "@/components/auth/AuthGoogleError";
import CompleteSignup from "@/components/auth/CompleteSignup";
import AcceptInvite from "@/components/containers/AcceptInvite";
import AuthGoogleComplete from "@/components/containers/AuthGoogleComplete";
import CreateTeam from "@/components/containers/CreateTeam";
import Login from "@/components/containers/Login";
import Signup from "@/components/containers/Signup";
import TeamSelection from "@/components/containers/TeamSelection";
import VerifySignup from "@/components/containers/VerifySignup";
import { useAppState } from "@/context/app-state";
import { useFeatureFlag, useKotsInstallerAppFlag } from "@/hooks/use-flag";
import useLegacyBreakpoint from "@/hooks/use-legacy-breakpoint";
import useSendEvent from "@/hooks/use-send-event";
import {
  useFeatureValues,
  useLogOutUserMutation,
  usePlatformAppsQuery,
  useTeam,
  useTeamEntitlements
} from "@/query";
import { AsyncAIModels, AsyncCompatibilityMatrix } from "../apps/AsyncComponents";
import SAML from "../auth/SAML";

const useAnalytics = ({ enabled }: { enabled: boolean }) => {
  const location = useLocation();
  const lastLocation = useRef<typeof location | null>(null);

  const sendEvent = useSendEvent();

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (location.pathname !== lastLocation.current?.pathname) {
      sendEvent("page_view");
    } else if (location.search !== lastLocation.current?.search) {
      sendEvent("params_change");
    } else if (location.hash !== lastLocation.current?.hash) {
      sendEvent("hash_change");
    }
    lastLocation.current = location;
  }, [location]);
  return null;
};

const Main = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [hasProcessedLegacyStaticRedirects, setHasProcessedLegacyStaticRedirects] =
    useState(false);
  const [hasProcessedLegacySlug, setHasProcessedLegacySlug] = useState(false);
  const breakpoint = useLegacyBreakpoint();
  const history = useHistory();
  const location = useLocation();

  const isKotsInstallerApp = useKotsInstallerAppFlag();
  const isAIModelsEnabled = useFeatureFlag("ai_model_repository");
  const {
    accessToken,
    currentAppId,
    isTroubleshootOpenedFromNavbar,
    setIsTroubleshootOpenedFromNavbar,
    user
  } = useAppState();
  const { features } = useFeatureValues();

  const team = useTeam();
  const teamEntitlements = useTeamEntitlements();
  const { data: platformAppsData, isSuccess: platformAppsQueryIsSuccess } =
    usePlatformAppsQuery();

  const platformApps = platformAppsData?.platformApps;
  const platformAppsList = platformAppsData?.platformAppsList;

  const { mutate: logOutUser } = useLogOutUserMutation();

  useAnalytics({ enabled: Boolean(user?.id && accessToken && team?.id) });

  const getNextPath = () => {
    const nextPath = sanitizeUrl(location.hash.slice(1));
    const isValid = VendorUtilities.validReplicatedUrl(nextPath);
    if (isValid) {
      return nextPath;
    }
    return "";
  };

  const shouldProcessUrlWithHash = Boolean(
    location.hash &&
      !location.hash.includes("#L") &&
      location.pathname.indexOf("troubleshoot") !== -1
  );

  // This is gross but it's supporting legacy code from original VenWeb
  const processLegacyStaticRedirects = () => {
    const nextPath = getNextPath();
    if (!nextPath.includes("/account") && !nextPath.includes("/password-reset")) {
      history.replace(nextPath);
      return;
    }
    if (!nextPath.includes("/account")) {
      return;
    }
    if (!accessToken) {
      logOutUser(true);
      return;
    }
    if (["/select-team", "/create-team", "/invite"].includes(location.pathname)) {
      return;
    }
    setHasProcessedLegacyStaticRedirects(true);
  };

  const processLegacySlug = () => {
    if (isEmpty(platformApps) || !platformAppsList) {
      history.replace("/new-application");
      return;
    }
    const platformSlug = platformApps[platformAppsList[0]]?.Slug;
    if (!platformSlug) {
      return;
    }
    const nextPath = getNextPath();
    history.replace(`/apps/${platformSlug}${nextPath}`);
  };

  useEffect(() => {
    if (!shouldProcessUrlWithHash) {
      VendorUtilities.purgeVOneData();
    }
  }, [shouldProcessUrlWithHash]);

  useEffect(() => {
    if (
      hasProcessedLegacyStaticRedirects &&
      !hasProcessedLegacySlug &&
      platformAppsQueryIsSuccess
    ) {
      setHasProcessedLegacySlug(true);
      processLegacySlug();
    }
  }, [
    platformAppsQueryIsSuccess,
    hasProcessedLegacyStaticRedirects,
    hasProcessedLegacySlug
  ]);

  useEffect(() => {
    if (shouldProcessUrlWithHash) {
      processLegacyStaticRedirects();
    }
  }, [shouldProcessUrlWithHash]);

  useEffect(() => {
    if (user?.id && user.is_pwned) {
      setShowBanner(true);
    }
  }, [user]);

  const isAuthPage =
    !accessToken || (accessToken && !user?.id) || location.pathname === "/saml";

  if (!isAuthPage && isEmpty(features)) {
    return null;
  }

  return (
    <div className="u-minHeight--full u-minWidth--full flex-column flex1">
      {!isAuthPage && (
        <>
          <NavBar
            hasLegacySchedulers={teamEntitlements?.native_scheduler}
            handleLogOut={logOutUser}
            user={user}
            breakpoint={breakpoint}
            isTroubleshootOpenedFromNavbar={isTroubleshootOpenedFromNavbar}
            setIsTroubleshootOpenFromNavbar={setIsTroubleshootOpenedFromNavbar}
            selectedApp={currentAppId}
            apps={platformApps}
            isKotsInstallerApp={isKotsInstallerApp}
            isAIModelsEnabled={isAIModelsEnabled}
          />
          <LegacySchedulersEOLBanner />
        </>
      )}
      <CompromisedPasswordBanner
        showBanner={showBanner}
        onCloseBanner={() => setShowBanner(false)}
      />
      <div className="flex flex1" style={{ minHeight: "0" }}>
        <Switch>
          <Route
            exact
            path="/crashz"
            render={() => {
              const Crashz = () => {
                throw new Error("Crashz!");
              };

              return <Crashz />;
            }}
          />
          <Route
            exact
            path="/login"
            render={() => (
              <>
                <PageTitle>Log in to Replicated</PageTitle>
                <Login breakpoint={breakpoint} />
              </>
            )}
          />
          <Route exact path="/auth/google/error" render={() => <AuthGoogleError />} />
          <Route
            exact
            path="/auth/google/complete"
            render={() => <AuthGoogleComplete breakpoint={breakpoint} />}
          />
          <Route exact path="/logout" render={() => <Logout />} />
          <Route
            exact
            path="/signup"
            render={() => (
              <>
                <PageTitle>Sign up to Replicated</PageTitle>
                <Signup />
              </>
            )}
          />
          <Redirect from="/signup-builders" to="/signup" />
          <Route
            exact
            path="/signup-business"
            render={() => (
              <>
                <PageTitle>Sign up to Replicated</PageTitle>
                <Signup />
              </>
            )}
          />
          <Route
            exact
            path="/invite"
            render={() => (
              <>
                <PageTitle>Accept Invite</PageTitle>
                <AcceptInvite />
              </>
            )}
          />
          <Route
            exact
            path="/signup/verify"
            render={() => (
              <>
                <PageTitle>Activate</PageTitle>
                <VerifySignup />
              </>
            )}
          />
          <Route
            exact
            path="/complete_signup"
            render={() => (
              <>
                <PageTitle>Log in to Replicated</PageTitle>
                <CompleteSignup />
              </>
            )}
          />
          <Route
            exact
            path="/create-team"
            render={() => (
              <>
                <PageTitle>Create Team</PageTitle>
                <CreateTeam />
              </>
            )}
          />
          <Route
            exact
            path="/select-team"
            render={() => (
              <>
                <PageTitle>Select Team</PageTitle>
                <TeamSelection />
              </>
            )}
          />
          <Route
            exact
            path="/saml"
            render={() => (
              <>
                <PageTitle>Log in to Replicated</PageTitle>
                <SAML />
              </>
            )}
          />
          <Route
            exact
            path="/saml/multiple_teams"
            render={() => <MultipleTeamsError breakpoint={breakpoint} />}
          />
          <Route exact path="/account/locked" render={() => <AccountLocked />} />
          <Route exact path="/account/unlock" render={() => <AccountUnlock />} />
          <Route
            exact
            path="/account/password-reset"
            render={() => (
              <>
                <PageTitle>Reset Password</PageTitle>
                <PasswordReset />
              </>
            )}
          />
          <Route
            exact
            path="/"
            render={() => <Redirect to={accessToken ? "/apps" : "/login"} />}
          />
          <Route exact path="/app" render={() => <Redirect to="/apps" />} />
          <Route exact path="/app/*" render={() => <Redirect to="/apps/*" />} />
          <Route
            path="/apps/:appSlug?"
            render={props => (
              <AsyncAppsTroubleshootContainer>
                <AsyncApps
                  breakpoint={breakpoint}
                  user={user}
                  location={props.location}
                />
              </AsyncAppsTroubleshootContainer>
            )}
          />
          <Route
            exact
            path="/new-application"
            render={() => (
              <>
                <PageTitle>New Application</PageTitle>
                <AsyncCreateApp breakpoint={breakpoint} user={user} />
              </>
            )}
          />
          <Route
            path="/team"
            render={props => (
              <>
                <PageTitle>Team</PageTitle>
                <AsyncTeamSettingsComponent
                  breakpoint={breakpoint}
                  location={props.location}
                  user={user}
                />
              </>
            )}
          />
          <Route
            exact
            path="/troubleshoot/collectors"
            render={() => <ReplRedirect nextRoute="/troubleshoot/collectors" />}
          />
          <Route
            exact
            path="/troubleshoot/analyzers"
            render={() => <ReplRedirect nextRoute="/troubleshoot/analyzers" />}
          />
          <Route
            path="/troubleshoot"
            render={() => (
              <AsyncAppsTroubleshootContainer>
                <AsyncTroubleshootContainer
                  breakpoint={breakpoint}
                  user={user}
                  team={team}
                  teamEntitlements={teamEntitlements}
                />
              </AsyncAppsTroubleshootContainer>
            )}
          />
          <Route
            path="/compatibility-matrix"
            render={() => (
              <>
                <PageTitle>Compatibility Matrix</PageTitle>
                <AsyncCompatibilityMatrix />
              </>
            )}
          />
          <Route
            path="/ai-models"
            render={() => (
              <>
                <PageTitle>AI Models</PageTitle>
                <AsyncAIModels />
              </>
            )}
          />

          <Route
            exact
            path="/changelog"
            render={() => (
              <>
                <PageTitle>Changelog</PageTitle>
                <RedirectChangelog />
              </>
            )}
          />
          <Route
            exact
            path="/releases"
            render={() => <ReplRedirect nextRoute="/releases" />}
          />
          <Route
            exact
            path="/channels"
            render={() => <ReplRedirect nextRoute="/channels" />}
          />
          <Route
            exact
            path="/licenses"
            render={() => <ReplRedirect nextRoute="/customers" />}
          />
          <Route
            exact
            path="/customers"
            render={() => <ReplRedirect nextRoute="/customers" />}
          />
          <Route
            exact
            path="/license-fields"
            render={() => <ReplRedirect nextRoute="/license-fields" />}
          />
          <Route
            exact
            path="/images"
            render={() => <ReplRedirect nextRoute="/images" />}
          />
          <Route exact path="/kurl" render={() => <ReplRedirect nextRoute="/kurl" />} />
          <Route
            exact
            path="/settings"
            render={() => <ReplRedirect nextRoute="/settings" />}
          />
          <Route
            exact
            path="/support"
            render={() => (
              <AsyncAppsTroubleshootContainer>
                <PageTitle>Support</PageTitle>
                <AsyncSupport breakpoint={breakpoint} />
              </AsyncAppsTroubleshootContainer>
            )}
          />
          <Route
            exact
            path="/require-2fa"
            render={() => <RequireTwoFactor breakpoint={breakpoint} />}
          />

          <Route
            exact
            path="/cli-login"
            render={() => (
              <>
                <PageTitle>CLI Login</PageTitle>
                <AsyncCLILogin />
              </>
            )}
          />

          <Route
            path="/account-settings"
            render={() => (
              <>
                <PageTitle>Account Settings</PageTitle>
                <AsyncAccountSettings breakpoint={breakpoint} />
              </>
            )}
          />
          <Route
            exact
            path="/privacy"
            render={() => (
              <>
                <PageTitle>Privacy Policy</PageTitle>
                <PrivacyPolicy />
              </>
            )}
          />
          <Route
            exact
            path="/terms"
            render={() => (
              <>
                <PageTitle>Terms of Service</PageTitle>
                <TermsOfService />
              </>
            )}
          />
          <Redirect from="/audit-log" to="/team/audit-log" />

          <Route
            render={() => (
              <>
                <PageTitle>404</PageTitle>
                <NotFound />
              </>
            )}
          />
        </Switch>
      </div>
      <LogOutErrorModal />
    </div>
  );
};

export default Main;
