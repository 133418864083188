import { Component } from "react";
import assign from "object-assign";
import autoBind from "react-autobind";
import MobileNavBar from "./MobileNavBar";
import NavItem from "./NavItem";
import { withRouter, Link } from "react-router-dom";
import Avatar from "react-avatar";

import "../../scss/components/main/NavBar.scss";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      userDropdownActive: false,
      mobileNavIsOpen: false
    };
    autoBind(this);
  }

  closeUserDropdown = () => {
    this.setState({
      userDropdownActive: false
    });
  };

  isActive = (pathname = "") => {
    return (item = {}) => {
      if (!item.linkTo) {
        return false;
      }
      if (item.linkTo === "/") {
        return pathname.startsWith("/apps");
      }
      if (item.linkTo === "/team/members") {
        return pathname.startsWith("/team");
      }
      if (item.linkTo === "/compatibility-matrix/clusters") {
        return pathname.startsWith("/compatibility-matrix");
      }
      return pathname.startsWith(`${item.linkTo}`);
    };
  };

  getNavItems = () => {
    const {
      twoFactorEnforced,
      twoFactorEnabled,
      isAIModelsEnabled
    } = this.props;
    const enforced2fa = twoFactorEnforced && !twoFactorEnabled;
    if (enforced2fa) {
      return [null, null];
    } else {
      return [
        {
          linkTo: `/`,
          label: "Applications",
          position: "left"
        },
        {
          linkTo: "/troubleshoot",
          label: "Troubleshoot",
          position: "left"
        },
        {
          linkTo: "/compatibility-matrix/clusters",
          label: "Compatibility Matrix",
          position: "left"
        },
        isAIModelsEnabled && {
          linkTo: "/ai-models",
          label: "AI Models",
          position: "left"
        }
      ];
    }
  };

  getStaticItems = () => {
    const isMobile = this.props.breakpoint === "mobile";
    if (isMobile) {
      return [];
    }

    return [
      {
        linkTo: "/support",
        label: "Support",
        position: "right"
      },
      {
        linkTo: "/team/members",
        label: "Team",
        position: "right",
        target: "_blank"
      },
      {
        href:
          !this.props.isKotsInstallerApp && this.props.hasLegacySchedulers
            ? "https://help.replicated.com/"
            : "https://docs.replicated.com/",
        label: "Docs",
        position: "right",
        target: "_blank"
      }
    ];
  };

  handleSignupClick = () => {
    this.props.history.push("/signup");
  };

  handleLogOut = () => {
    if (this.state.mobileNavIsOpen) {
      this.setState({
        mobileNavIsOpen: false
      });
    }
    this.props.handleLogOut(true);
  };

  handleRouteChange = route => {
    if (this.state.userDropdownActive) {
      this.closeUserDropdown();
    }
    if (this.state.mobileNavIsOpen) {
      this.setState({
        mobileNavIsOpen: false
      });
    }
    this.props.history.push(route);
  };

  getUser = () => {
    const { user } = this.props;
    const isMobile = this.props.breakpoint === "mobile";
    const missingRequired2fa = user["2fa_enforced"] && !user["2fa_enabled"];

    return [
      {
        id: "userDropdown",
        className: "",
        position: "right",
        showMobileBar: false,
        icon: [
          <div key="user-wrapper" className="u-position--relative UserPhoto-wrapper">
            {!!this.props.user?.email && (
              <>
                <Avatar
                  name={this.props.user.email}
                  size={30}
                  color="#6a77fb"
                  textSizeRatio={2}
                  key="photo"
                  className="UserPhoto"
                />
                <span key="arrow" className="icon clickable u-dropdownArrowIcon"></span>
              </>
            )}
          </div>
        ],
        dropdownContent: [
          missingRequired2fa
            ? null
            : isMobile
            ? {
                className: "DropdownItem",
                label: "Team Settings",
                onClick: () => {
                  this.handleRouteChange("/team/members");
                }
              }
            : null,
          isMobile
            ? {
                className: "DropdownItem",
                label: "Support",
                onClick: () => {
                  this.handleRouteChange("/support");
                }
              }
            : null,
          isMobile
            ? {
                className: "DropdownItem",
                label: "Docs",
                href:
                  !this.props.isKotsInstallerApp && this.props.hasLegacySchedulers
                    ? "https://help.replicated.com/"
                    : "https://docs.replicated.com/"
              }
            : null,
          missingRequired2fa
            ? null
            : {
                className: "DropdownItem",
                label: "Account Settings",
                onClick: () => {
                  this.handleRouteChange("/account-settings");
                }
              },
          missingRequired2fa
            ? null
            : {
                className: "DropdownItem",
                label: "Audit Log",
                onClick: () => {
                  this.handleRouteChange("/team/audit-log?from=user-dropdown");
                }
              },
          {
            className: "DropdownItem",
            label: "Changelog",
            href:
              !this.props.isKotsInstallerApp && this.props.hasLegacySchedulers
                ? "https://release-notes.replicated.com/"
                : "https://docs.replicated.com/release-notes/rn-vendor-platform"
          },
          {
            className: "DropdownItem",
            label: "Log out",
            onClick: this.handleLogOut
          }
        ]
      }
    ];
  };

  combineItems = itemsArr => {
    return itemsArr.reduce((accum, method) => accum.concat(method(this.props)), []);
  };

  onClick = item => {
    return (e, ...rest) => {
      const activeKey = `${item.label || item.id || ""}Active`;
      if (item.label === "Troubleshoot") {
        this.props.setIsTroubleshootOpenFromNavbar();
      }
      if (item.href) {
        return;
      }
      if (typeof item.onClick === "function") {
        item.onClick(e, ...rest);
        return;
      }
      this.setState({
        [activeKey]: !this.state[activeKey]
      });
    };
  };

  render() {
    const { className, user } = this.props;

    let itemsArr = [];
    const isPathActive = this.isActive(
      typeof window === "object" ? window.location.pathname : ""
    );

    // get items
    if (user?.id) {
      itemsArr.push(this.getNavItems);
      itemsArr.push(this.getStaticItems);
      itemsArr.push(this.getUser);
    }

    // build items
    const headerItems = this.combineItems(itemsArr)
      .filter(item => item)
      .map(item =>
        assign(item, {
          isActive:
            isPathActive(item) &&
            (item.label !== "Troubleshoot" || this.props.isTroubleshootOpenedFromNavbar)
        })
      );
    const renderItem = (item, index) => {
      return (
        <NavItem
          key={index}
          {...item}
          onClick={this.onClick(item)}
          isDropDownActive={this.state[`${item.label || item.id || index || ""}Active`]}
        />
      );
    };

    const rightItems = headerItems.filter(item => item.position === "right");
    const leftItems = headerItems.filter(item => item.position === "left");

    const isMobile = this.props.breakpoint === "mobile";
    let currentApp;
    if (
      this.props.apps &&
      this.props.selectedApp &&
      this.props.apps[this.props.selectedApp]
    ) {
      currentApp =
        this.props.apps[this.props.selectedApp].slug ||
        this.props.apps[this.props.selectedApp].Slug;
    }

    return (
      <div
        className={`NavBarWrapper flex flex-auto ${className || ""} ${
          isMobile ? "nav-mobile" : ""
        }`}
      >
        <div className="container flex flex1">
          <div className="flex1 justifyContent--flexStart alignItems--center">
            {isMobile ? (
              <div className="flex flex1">
                <div className="MobileTrigger flex1 flex-column flex-verticalCenter">
                  <NavItem
                    icon={
                      <span
                        className={`icon clickable ${
                          this.state.mobileNavIsOpen ? "u-closeIcon" : "u-listIcon"
                        }`}
                      ></span>
                    }
                    onClick={() => {
                      this.setState({
                        mobileNavIsOpen: !this.state.mobileNavIsOpen
                      });
                    }}
                  />
                </div>
                <div className="HeaderLogo flex1 flex justifyContent--center alignItems--center">
                  {currentApp !== undefined ? (
                    <Link to={`/apps/${currentApp}/channels`} tabIndex="-1">
                      <span className="logo"></span>
                    </Link>
                  ) : (
                    <Link to="/" tabIndex="-1">
                      <span className="logo"></span>
                    </Link>
                  )}
                </div>
                <div className="flex flex1 justifyContent--flexEnd">
                  <div className="flex flex-auto alignItems--center">
                    {!!this.props.user?.email && (
                      <NavItem
                        linkTo="/account-settings/info"
                        icon={[
                          <div
                            key="user-wrapper"
                            className="u-position--relative UserPhoto-wrapper"
                          >
                            <Avatar
                              name={this.props.user.email}
                              size={30}
                              color="#6a77fb"
                              textSizeRatio={2}
                              round={true}
                              key="photo"
                              className="UserPhoto"
                            />
                          </div>
                        ]}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex1 flex">
                <div className="flex flex-auto">
                  <div className="HeaderLogo-wrapper u-position--relative">
                    <div className="HeaderLogo">
                      {currentApp !== undefined ? (
                        <Link to={`/apps/${currentApp}/channels`} tabIndex="-1">
                          <span className="logo"></span>
                        </Link>
                      ) : (
                        <Link to="/" tabIndex="-1">
                          <span className="logo"></span>
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-auto alignItems--center left-items">
                    {leftItems.map(renderItem)}
                  </div>
                </div>
                <div className="flex flex1 justifyContent--flexEnd right-items">
                  {this.props.err ? (
                    <div className="ErrorBlock u-marginBottom--small">
                      <p>{this.props.errMessage}</p>
                    </div>
                  ) : null}
                  <div className="flex flex-auto alignItems--center">
                    {rightItems.map(renderItem)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.userDropdownActive ? (
          <div className="hidden-trigger" onClick={this.closeUserDropdown}></div>
        ) : null}
        {isMobile ? (
          <MobileNavBar
            user={user}
            className="MobileNavBar"
            items={headerItems}
            isOpen={this.state.mobileNavIsOpen}
            onClose={() => {
              this.setState({
                mobileNavIsOpen: false
              });
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(NavBar);
